<template>
  <div>
    <Spinner v-if="$store.state.loadSpinner" />

    <WidgetsDropdown :statistics="statistics" v-if="statistics" />

    <StatChartComponent v-if="lawyerChartDataPerMonth && lawyerChartDataPerStatus" headerTitle="معدل تسجيل المحامين لكل شهر" secondHeaderTitle="حالات المحامين" :barData="lawyerChartDataPerMonth" :pieData="lawyerChartDataPerStatus" entity="lawyers" />
    <StatChartComponent v-if="clientChartDataPerMonth && clientChartDataPerStatus" headerTitle="معدل تسجيل العملاء لكل شهر" secondHeaderTitle="حالات العملاء" :barData="clientChartDataPerMonth" :pieData="clientChartDataPerStatus" entity="clients" />

  </div>
</template>

<script>
import WidgetsDropdown from "./WidgetsDropdown";
import StatChartComponent from "./StatChartComponent";

export default {
  name: "Dashboard",
  components: {
    WidgetsDropdown,
    StatChartComponent
  },
  data() {
    return {
      statistics: null,
      lawyerChartDataPerMonth: null,
      lawyerChartDataPerStatus: null,
      clientChartDataPerMonth: null,
      clientChartDataPerStatus: null
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    // dashboard
    fetchData(page = 1) {
      this.toggleLoadSpinner();

      this.$http
        .get(`/stats`)
        .then((res) => {
          // handle success
          this.statistics = res.data.data;

          this.lawyerChartDataPerMonth = res.data.data.lawyersPerMonth;
          this.lawyerChartDataPerStatus = res.data.data.lawyersStatusCount;

          this.clientChartDataPerMonth = res.data.data.clientsPerMonth;
          this.clientChartDataPerStatus = res.data.data.clientsStatusCount;

          this.toggleLoadSpinner();
        })
        .catch((error) => {
          //
          this.toggleLoadSpinner();
        });
    },
    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    }
  },
};
</script>
