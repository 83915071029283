<template>
  <CRow>
    <CCol sm="12">
      <CRow>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            :header="statistics.clientsCount"
            text="عميل"
            color="gradient-info"
            inverse
          >
            <CIcon name="cil-people" height="36" />
          </CWidgetProgressIcon>
        </CCol>

        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            :header="statistics.lawyersCount"
            text="محامي"
            color="gradient-info"
            inverse
          >
            <CIcon name="cil-basket" height="36" />
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            :header="statistics.servicesCount"
            text="خدمة"
            color="gradient-primary"
            inverse
          >
            <CIcon name="cib-adguard" height="36" />
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            :header="statistics.categoriesCount"
            text="تصنيف"
            color="gradient-danger"
            inverse
          >
            <CIcon name="cil-image" height="36" />
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            :header="statistics.bookingsCount"
            text="حجز"
            color="gradient-success"
            inverse
          >
            <CIcon name="cil-image" height="36" />
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            :header="statistics.consultationsCount"
            text="استشارة"
            color="gradient-primary"
            inverse
          >
            <CIcon name="cil-image" height="36" />
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            :header="statistics.servicesRequestCount"
            text="طلب خدمة"
            color="gradient-warning"
            inverse
          >
            <CIcon name="cil-image" height="36" />
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            :header="statistics.questionsCount"
            text="سؤال"
            color="gradient-danger"
            inverse
          >
            <CIcon name="cil-image" height="36" />
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            :header="statistics.answersCount"
            text="اجابة"
            color="gradient-success"
            inverse
          >
            <CIcon name="cil-image" height="36" />
          </CWidgetProgressIcon>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>

export default {
  name: "WidgetsDropdown",
  props: ["statistics"]
};
</script>
